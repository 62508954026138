import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';




let savedCount = localStorage.getItem('count') + 1;
const root = ReactDOM.createRoot(document.getElementById('root'));

if(savedCount != null)
savedCount += 1 
else
 localStorage.setItem('count', savedCount)

 console.log(savedCount)

console.log(savedCount, "Cnt")
root.render(
      <App />
 
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
